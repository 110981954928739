import { cn } from '@/core/ui/utils';

import Facebook from '../images/facebook-icon.svg';
import Instagram from '../images/instagram-icon.svg';
import Youtube from '../images/youtube-icon.svg';

export const FooterSocialMediaSection = () => (
  <div
    className={cn(
      'flex',
      'gap-5',
      'lg:mt-4',
      'border-t',
      'lg:border-0',
      'border-primary-200',
      'p-4',
      'lg:p-0',
      'pt-6'
    )}
  >
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.facebook.com/zabkajush/"
      aria-label="Facebook"
    >
      <Facebook
        className={cn('w-5', 'h-6', 'text-primary-200', '-translate-y-px')}
      />
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.instagram.com/zabkajush/"
      aria-label="Instagram"
    >
      <Instagram className={cn('w-[18px]', 'h-[18px]', 'text-primary-200')} />
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.youtube.com/@zabkajush"
      aria-label="Youtube"
    >
      <Youtube className={cn('w-[22px]', 'h-[18px]', 'text-primary-200')} />
    </a>
  </div>
);
